<template>
	<div class="page container">

		<div class="page__info" v-if="getNameString">
			<div class="page__info--title">
				{{ getNameString }}
			</div>
		</div>

		<div>
			<list-view
				v-if="getUser"
				:list="getList"
				:actions="getActions"
				@actionClick="onActionClick"
			/>
		</div>

		<portal
			to="modal"
			v-if="removeTeacherConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleRemoveTeacherConfirmation"
				heading="Remove teacher"
			>
				<confirmation-modal
					text="This will prevent the user from accessing the teaching section of Bridge128."
					confirmationText="Remove teacher"
					confirmationActionId="setUserIsNotTeacherById"
					cancelActionId="toggleRemoveTeacherConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="removeAdminConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleRemoveAdminConfirmation"
				heading="Remove admin"
			>
				<confirmation-modal
					text="This will prevent the user from accessing the admin section of Bridge128."
					confirmationText="Remove admin"
					confirmationActionId="setUserIsNotAdminById"
					cancelActionId="toggleRemoveAdminConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>
	import ListView            from '@/components/ui/views/listView/ListView';
	import ModalInner          from '@/components/ui/modal/ModalInner';
	import ConfirmationModal   from '@/components/ui/modal/ConfirmationModal';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import routeParams         from '@/mixins/routeParams';
	import api                 from '@/services/api';
	import dayjs               from 'dayjs';
	import relativeTime        from 'dayjs/plugin/relativeTime';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'User overview'
		},
		components: {
			ListView,
			ModalInner,
			ConfirmationModal
		},
		mixins: [actionClick, routeParams, invalidFormMessage],
		data: () => ({
			user: undefined,
			removeTeacherConfirmationIsVisible:  false,
			removeAdminConfirmationIsVisible:  false
		}),
		computed: {
			getUser () {
				if (!this.user ||
						!this.user.id) {
					return false;
				}
				return this.user;
			},
			getNameString () {
				if (!this.getUser) {
					return false;
				}
				return `${this.getUser.firstName} ${this.getUser.lastName}`;
			},
			getIsTeacher () {
				return this.user?.isTeacher;
			},
			getIsTeacherString () {
				if (!this.getIsTeacher) {
					return 'Is not a teacher';
				}
				return 'Is a teacher';
			},
			getTeacherProfileId () {
				if (!this.getIsTeacher ||
						!this.getUser?.teacherProfileId) {
					return false;
				}
				return this.getUser.teacherProfileId;
			},
			getTeacherProfileEditRoute () {
				if (!this.getIsTeacher) {
					return false;
				}
				return `/admin/teachers/${this.getTeacherProfileId}`;
			},
			getTeacherProfileViewRoute () {
				if (!this.getIsTeacher) {
					return false;
				}
				return `/teachers/${this.getTeacherProfileId}`;
			},
			getIsAdminString () {
				if (!this.user.isAdmin) {
					return 'Is not an admin';
				}
				return 'Is an admin';
			},
			getEmailString () {
				return `${this.getUser.email} (${this.getIsVerifiedString})`;
			},
			getIsVerifiedString () {
				if (!this.user.verified) {
					return 'Not verified';
				}
				return `verified ${dayjs().to(this.user.verifiedDate)}`;
			},
			getGdprConsentString () {
				if (!this.user.gdprConsent) {
					return 'Has not consented to being on the mailing list';
				}
				return 'Has consented to being on the mailing list';
			},
			getList () {
				if (!this.getUser) {
					return false;
				}
				return [
					{
						// text: this.getUser.email
						text: this.getEmailString
					},
					{
						text: this.getIsAdminString
					},
					{
						text: this.getIsTeacherString
					},
					{
						text: this.getGdprConsentString
					},
					{
						text: 'Purchases',
						route: `/admin/users/${this.getUserId}/purchases`
					},
					(this.user.isTeacher ?
						{
							text: 'Sales',
							route: `/admin/users/${this.getUserId}/sales`
						} : false
					),
					(this.user.isTeacher ?
							{
								text: 'Transactions',
								route: `/admin/users/${this.getUserId}/transactions`
							} : false
					),
					(this.user.isTeacher ?
						{
							text: 'Commission',
							route: `/admin/users/${this.getUserId}/commission`
						} : false
					),
					{
						text: 'Edit user',
						route: `/admin/users/${this.getUserId}/edit`
					},
					(this.getTeacherProfileViewRoute ?
						{
							text: 'View teacher profile',
							route: this.getTeacherProfileViewRoute
						} : false
					),
					(this.getTeacherProfileEditRoute ?
						{
							text: 'Edit teacher profile',
							route: this.getTeacherProfileEditRoute
						} : false
					)
				].filter(Boolean);
			},
			getActions () {
				return {
					secondary: [
						(!this.user.isTeacher ?
							{
								text: 'Make teacher',
								actionId: 'setUserIsTeacherById'
							} : false
						),
						(this.user.isTeacher ?
							{
								text: 'Remove teacher',
								actionId: 'toggleRemoveTeacherConfirmation'
							} : false
						),
						(!this.user.isAdmin ?
							{
								text: 'Make admin',
								actionId: 'setUserIsAdminById'
							} : false
						),
						(this.user.isAdmin ?
							{
								text: 'Remove admin',
								actionId: 'toggleRemoveAdminConfirmation'
							} : false
						)
					].filter(Boolean)
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/users',
						text: 'Users'
					},
					{
						path: `/admin/users/${this.getUserId}`,
						text: this.getNameString
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setUser();
		},
		methods: {
			async setUserIsTeacherById () {
				const user = await api.admin.setUserIsTeacherById({
					fields: [
						'id'
					],
					userId: this.getUserId
				});
				if (!user) {
					return false;
				}
				this.user = user;
				this.$store.commit('ui/showNotification', {
					notification: 'User has been updated'
				});
			},
			toggleRemoveTeacherConfirmation () {
				this.removeTeacherConfirmationIsVisible = !this.removeTeacherConfirmationIsVisible;
			},
			async setUserIsNotTeacherById () {
				this.toggleRemoveTeacherConfirmation();
				const user = await api.admin.setUserIsNotTeacherById({
					userId: this.getUserId
				});
				if (!user) {
					return false;
				}
				this.user = user;
				this.$store.commit('ui/showNotification', {
					notification: 'User has been updated'
				});
			},
			async setUserIsAdminById () {
				const user = await api.admin.setUserIsAdminById({
					fields: [
						'id'
					],
					userId: this.getUserId
				});
				if (!user) {
					return false;
				}
				this.user = user;
				this.$store.commit('ui/showNotification', {
					notification: 'User has been updated'
				});
			},
			toggleRemoveAdminConfirmation () {
				this.removeAdminConfirmationIsVisible = !this.removeAdminConfirmationIsVisible;
			},
			async setUserIsNotAdminById () {
				this.toggleRemoveAdminConfirmation();
				const user = await api.admin.setUserIsNotAdminById({
					fields: [
						'id'
					],
					userId: this.getUserId
				});
				if (!user) {
					return false;
				}
				this.user = user;
				this.$store.commit('ui/showNotification', {
					notification: 'User has been updated'
				});
			},
			async setUser () {
				const user = await api.admin.getUserById({
					userId: this.getUserId
				});
				if (!user) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.user = user;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
